import React, { useEffect, useState } from "react";
import "./style.css";
import Image from "../../Assets/Images/hero-bg.png";
import Image1 from "../../Assets/Images/intro-image1.png";
import Image2 from "../../Assets/Images/intro-image2.png";
import HeroSection from "./HeroSection";
import Box from "./Box";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const history = useNavigate();
  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const heroData = [
    {
      text: " efficiency gains",
      sectionClass: "c-hero",
      img: "https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/hero/hero1.jpg",
    },
    {
      text: " a seamless transition",
      sectionClass: "c-hero1",
      img: "https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/hero/hero3.jpg",
    },
    {
      text: " your digital transformation",
      sectionClass: "c-hero2",
      img: "https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/hero/hero2.jpg",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % heroData.length);
    }, 6000);

    return () => clearInterval(interval);
  }, []);
  return (
    <div className="dashboard-div">
      <div className="dashboard-img-div">
        {/* <img className="dashboard-img" src={Image} /> */}
        <HeroSection
          text={heroData[currentIndex].text}
          sectionClass={heroData[currentIndex].sectionClass}
          img={heroData[currentIndex].img}
        />

        <section className="sectionContainer">
          <div className="c-intro">
            <div className="c-intro__contentGroup c-intro__contentGroup--double">
              <div class="c-intro__content">
                <div class="c-intro__title">Who we are?</div>
                <p class="c-intro__text">
                  We are a team passionate about delivering the highest quality
                  training in Microsoft Teams and O365 with creativity and
                  innovation based on our extensive experience in the Digital
                  Classroom.
                </p>
              </div>
              <div class="c-intro__content">
                <div class="c-intro__title">Why Learnogic?</div>
                <p class="c-intro__text">
                  Our comprehensive support – from core functionalities to
                  subject focussed – will accelerate the development of teaching
                  and learning through the efficient application of digital
                  pedagogy.
                </p>
              </div>
            </div>
            <div class="c-intro__contentGroup c-intro__contentGroup--left">
              <img
                src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/img/intro-image1.png"
                alt=""
                class="c-intro__img"
              />
              <div class="c-intro__content">
                <div class="c-intro__title">Your digital transformation</div>
                <p class="c-intro__text">
                  Our training support provides a longer term strategy to
                  succeed with MS Teams for education. It highlights the
                  benefits of using productivity tools in achieving a highly
                  cost-effective channel of communication and collaboration.
                </p>
              </div>
            </div>
            <div class="c-intro__contentGroup c-intro__contentGroup--right">
              <img
                src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/img/intro-image2.png"
                alt=""
                class="c-intro__img"
              />
              <div class="c-intro__content">
                <div class="c-intro__title">Seamless transition</div>
                <div class="c-intro__text">
                  Our CPD certified training allows teachers to gain confidence
                  and embrace new ways of working. It facilitates a smooth
                  integration of the full suite of Teams distinctive tools,
                  enhancing the quality and value provided to their students.
                </div>
              </div>
            </div>
            <div class="c-intro__contentGroup c-intro__contentGroup--left">
              <img
                src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/img/intro-image3.png"
                alt=""
                class="c-intro__img"
              />
              <div class="c-intro__content">
                <div class="c-intro__title">Efficiency gains</div>
                <div class="c-intro__text">
                  Maximise efficacy by automating frequently performed tasks to
                  save time and costs by easing teacher workload. A centralised
                  approach optimises teachers non-teaching time and engages
                  every student in innovative methods of learning.
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="sectionContainer bgPureWhite">
          <div class="c-coursesTb responsivePadding">
            <div class="c-coursesTb__header">
              <h2 class="c-coursesTb__headerHeading">
                Learning with our experts
              </h2>
              <p class="c-coursesTb__headerIntro">
                Our training providers are <strong>experienced teachers</strong>{" "}
                who themselves have successfully integrated digital technology
                in all their subject areas across different key stages and year
                groups.
              </p>
            </div>
            <div class="c-coursesTb__thumbnails">
              <div class="c-coursesTb__thumbnail">
                <img
                  src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/courses/teams.png"
                  alt=""
                  class="c-coursesTb__thumbnailImg"
                />
                <div class="c-tag c-tag--bestSeller">Best seller</div>
                <div class="c-coursesTb__rating">
                  <div class="c-coursesTb__ratingScore">
                    4.8
                    <img
                      src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/star.svg"
                      alt=""
                      class="c-coursesTb__ratingStar"
                    />
                  </div>

                  <div class="c-coursesTb__ratingReviews">(1200)</div>
                </div>
                <div class="c-coursesTb__thumbnailTitle">
                  Libero non ultrices vitae nulla. In
                </div>
                <div class="c-coursesTb__thumbnailDuration">10 min</div>
                <a
                  style={{ color: "#707070" }}
                  // href="https://dilepex-lms.kau.agency/courses"
                  class="c-button c-button--tealOutline"
                >
                  Learn more
                </a>
              </div>
              <div class="c-coursesTb__thumbnail">
                <img
                  src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/courses/oneNote.png"
                  alt=""
                  class="c-coursesTb__thumbnailImg"
                />
                <div class="c-tag c-tag--new">New</div>
                <div class="c-coursesTb__rating">
                  <div class="c-coursesTb__ratingScore">
                    4.8
                    <img
                      src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/star.svg"
                      alt=""
                      class="c-coursesTb__ratingStar"
                    />
                  </div>

                  <div class="c-coursesTb__ratingReviews">(1200)</div>
                </div>
                <div class="c-coursesTb__thumbnailTitle">
                  Libero non ultrices vitae nulla. In
                </div>
                <div class="c-coursesTb__thumbnailDuration">10 min</div>
                <a
                  style={{ color: "#707070" }}
                  // href="https://dilepex-lms.kau.agency/courses"
                  class="c-button c-button--tealOutline"
                >
                  Learn more
                </a>
              </div>
              <div class="c-coursesTb__thumbnail">
                <img
                  src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/courses/classNotebook.png"
                  alt=""
                  class="c-coursesTb__thumbnailImg"
                />
                <div class="c-tag c-tag--bestSeller">Best Seller</div>
                <div class="c-coursesTb__rating">
                  <div class="c-coursesTb__ratingScore">
                    4.8
                    <img
                      src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/star.svg"
                      alt=""
                      class="c-coursesTb__ratingStar"
                    />
                  </div>

                  <div class="c-coursesTb__ratingReviews">(1200)</div>
                </div>
                <div class="c-coursesTb__thumbnailTitle">
                  Libero non ultrices vitae nulla. In
                </div>
                <div class="c-coursesTb__thumbnailDuration">10 min</div>
                <a
                  style={{ color: "#707070" }}
                  // href="https://dilepex-lms.kau.agency/courses"
                  class="c-button c-button--tealOutline"
                >
                  Learn more
                </a>
              </div>
            </div>
          </div>
        </section>
        <div class="spacing-48px"></div>
        <div className="bgContainer linearBG">
          <Box />{" "}
          {/* <section class="sectionContainer">
            <div class="c-blogTb responsivePadding">
              <div class="c-blogTb__header">
                <h2 class="c-blogTb__headerHeading">Our Journal</h2>
                <a class="c-button c-button--whiteOutline c-button--desktop">
                  Read more
                </a>
              </div>
              <div class="blog__posts blog__posts--thumbnail blog__posts--light">
                <div class="singlePost__otherPostsPost">
                  <a href="">
                    <img
                      width="1773"
                      height="2408"
                      src="https://dilepex-lms.kau.agency/wp-content/uploads/2023/08/F0ETxF_aYAEbulu.jpeg"
                      class="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                      alt=""
                      decoding="async"
                      fetchpriority="high"
                      srcset="https://dilepex-lms.kau.agency/wp-content/uploads/2023/08/F0ETxF_aYAEbulu.jpeg 1773w, https://dilepex-lms.kau.agency/wp-content/uploads/2023/08/F0ETxF_aYAEbulu-221x300.jpeg 221w, https://dilepex-lms.kau.agency/wp-content/uploads/2023/08/F0ETxF_aYAEbulu-754x1024.jpeg 754w, https://dilepex-lms.kau.agency/wp-content/uploads/2023/08/F0ETxF_aYAEbulu-768x1043.jpeg 768w, https://dilepex-lms.kau.agency/wp-content/uploads/2023/08/F0ETxF_aYAEbulu-1131x1536.jpeg 1131w, https://dilepex-lms.kau.agency/wp-content/uploads/2023/08/F0ETxF_aYAEbulu-1508x2048.jpeg 1508w, https://dilepex-lms.kau.agency/wp-content/uploads/2023/08/F0ETxF_aYAEbulu-600x815.jpeg 600w, https://dilepex-lms.kau.agency/wp-content/uploads/2023/08/F0ETxF_aYAEbulu-37x50.jpeg 37w"
                      sizes="(max-width: 1773px) 100vw, 1773px"
                    />
                  </a>
                  <div class="singlePost__thumbnailContentContainer">
                    <div class="singlePost__thumbnailContent">
                      <div class="c-blogSubtitle">
                        <div class="c-blogSubtitle__tag">
                          <div class="post-categories">Uncategorized</div>
                        </div>
                        | <div class="c-blogSubtitle__date">18 August 2023</div>
                      </div>
                      <a href="">
                        <h2>Another interesting blog here!</h2>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="singlePost__otherPostsPost">
                  <a href="">
                    <img
                      width="2560"
                      height="1707"
                      src="https://dilepex-lms.kau.agency/wp-content/uploads/2023/08/emre-CBh4D3l0EwM-unsplash-scaled-1.jpg"
                      class="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                      alt=""
                      decoding="async"
                      srcset="https://dilepex-lms.kau.agency/wp-content/uploads/2023/08/emre-CBh4D3l0EwM-unsplash-scaled-1.jpg 2560w, https://dilepex-lms.kau.agency/wp-content/uploads/2023/08/emre-CBh4D3l0EwM-unsplash-scaled-1-300x200.jpg 300w, https://dilepex-lms.kau.agency/wp-content/uploads/2023/08/emre-CBh4D3l0EwM-unsplash-scaled-1-1024x683.jpg 1024w, https://dilepex-lms.kau.agency/wp-content/uploads/2023/08/emre-CBh4D3l0EwM-unsplash-scaled-1-768x512.jpg 768w, https://dilepex-lms.kau.agency/wp-content/uploads/2023/08/emre-CBh4D3l0EwM-unsplash-scaled-1-1536x1024.jpg 1536w, https://dilepex-lms.kau.agency/wp-content/uploads/2023/08/emre-CBh4D3l0EwM-unsplash-scaled-1-2048x1366.jpg 2048w, https://dilepex-lms.kau.agency/wp-content/uploads/2023/08/emre-CBh4D3l0EwM-unsplash-scaled-1-600x400.jpg 600w, https://dilepex-lms.kau.agency/wp-content/uploads/2023/08/emre-CBh4D3l0EwM-unsplash-scaled-1-75x50.jpg 75w"
                      sizes="(max-width: 2560px) 100vw, 2560px"
                    />
                  </a>
                  <div class="singlePost__thumbnailContentContainer">
                    <div class="singlePost__thumbnailContent">
                      <div class="c-blogSubtitle">
                        <div class="c-blogSubtitle__tag">
                          <div class="post-categories">News</div>
                        </div>
                        | <div class="c-blogSubtitle__date">18 August 2023</div>
                      </div>
                      <a href="">
                        <h2>Microsoft 365 new updates and tips</h2>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="singlePost__otherPostsPost">
                  <a href="">
                    <img
                      width="2560"
                      height="1440"
                      src="https://dilepex-lms.kau.agency/wp-content/uploads/2023/08/classroom-scaled-1.jpg"
                      class="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                      alt=""
                      decoding="async"
                      srcset="https://dilepex-lms.kau.agency/wp-content/uploads/2023/08/classroom-scaled-1.jpg 2560w, https://dilepex-lms.kau.agency/wp-content/uploads/2023/08/classroom-scaled-1-300x169.jpg 300w, https://dilepex-lms.kau.agency/wp-content/uploads/2023/08/classroom-scaled-1-1024x576.jpg 1024w, https://dilepex-lms.kau.agency/wp-content/uploads/2023/08/classroom-scaled-1-768x432.jpg 768w, https://dilepex-lms.kau.agency/wp-content/uploads/2023/08/classroom-scaled-1-1536x864.jpg 1536w, https://dilepex-lms.kau.agency/wp-content/uploads/2023/08/classroom-scaled-1-2048x1152.jpg 2048w, https://dilepex-lms.kau.agency/wp-content/uploads/2023/08/classroom-scaled-1-600x338.jpg 600w, https://dilepex-lms.kau.agency/wp-content/uploads/2023/08/classroom-scaled-1-89x50.jpg 89w"
                      sizes="(max-width: 2560px) 100vw, 2560px"
                    />
                  </a>
                  <div class="singlePost__thumbnailContentContainer">
                    <div class="singlePost__thumbnailContent">
                      <div class="c-blogSubtitle">
                        <div class="c-blogSubtitle__tag">
                          <div class="post-categories">News</div>
                        </div>
                        | <div class="c-blogSubtitle__date">18 August 2023</div>
                      </div>
                      <a href="">
                        <h2>Another interesting Title</h2>
                      </a>
                    </div>
                  </div>
                </div>{" "}
              </div>

              {/* <button class="c-button c-button--whiteOutline c-button--mobile">
                Read more
              </button>
              <div class="c-blogTb__thumbnails">
                <div class="c-blogTb__thumbnail">
                  <img
                    src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/blog/interview.jpg"
                    alt=""
                    class="c-blogTb__thumbnailImg"
                  />
                  <div class="c-tag c-tag--tips">Tips</div>
                  <div class="c-blogSubtitle">
                    <div class="c-blogSubtitle__tag">News</div>|
                    <div class="c-blogSubtitle__date">23 / 08 / 2023</div>
                  </div>
                  <div class="c-blogTb__thumbnailTitle">
                    Libero non ultrices vitae nulla. In
                  </div>
                </div>
                <div class="c-blogTb__thumbnail">
                  <img
                    src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/blog/teacher.jpg"
                    alt=""
                    class="c-blogTb__thumbnailImg"
                  />
                  <div class="c-tag c-tag--updates">Updates</div>
                  <div class="c-blogSubtitle">
                    <div class="c-blogSubtitle__tag">News</div>|
                    <div class="c-blogSubtitle__date">23 / 08 / 2023</div>
                  </div>
                  <div class="c-blogTb__thumbnailTitle">
                    Egestas rhoncus viverra amet
                  </div>
                </div>
                <div class="c-blogTb__thumbnail">
                  <img
                    src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/blog/childVR.jpg"
                    alt=""
                    class="c-blogTb__thumbnailImg"
                  />
                  <div class="c-tag c-tag--news">News</div>
                  <div class="c-blogSubtitle">
                    <div class="c-blogSubtitle__tag">News</div>|
                    <div class="c-blogSubtitle__date">23 / 08 / 2023</div>
                  </div>
                  <div class="c-blogTb__thumbnailTitle">
                    Duis nibh magna consectetur
                  </div>
                </div>
              </div> */}
          {/* </div>
          </section> */}
          <div class="spacing-48px"></div>
          <section class="sectionContainer">
            <div class="c-cta">
              <div class="c-cta__title">Get in touch</div>
              <p class="c-cta__intro">
                Arrange an initial conversation with one of our digital learning
                pedagogy experts
              </p>
              <button
                class="c-button ctaOrange"
                style={{
                  background: "rgb(66 66 66)",
                  border: "none",
                  color: "#fff",
                }}
                onClick={() => {
                  history("/contact");
                }}
              >
                Contact us
              </button>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
