import React from "react";
import aboutus from "../../Assets/Images/aboutUs.jpg";
import "./style.css";
import { useNavigate } from "react-router-dom";

const About = () => {
  const history = useNavigate();
  return (
    <div>
      <div class="headerSpacing"></div>
      <section class="c-pageBanner c-pageBanner--aboutUs sectionContainer">
        <div class="c-pageBanner__backgroundOverlay"></div>
        <div class="c-pageBanner__container responsivePadding">
          <div class="c-pageBanner__content">
            <h1 class="c-pageBanner__heading">About us</h1>
          </div>
          <img src={aboutus} alt="" class="c-pageBanner__img" />
        </div>
      </section>
      <div class="spacing-48px"></div>
      <section class="sectionContainer">
        <div class="c-text c-text--aboutUs responsivePadding">
          <h2 class="c-text__title">
            Transforming teaching and learning. Empowering teachers and students
            across the education sector.
          </h2>
          <div class="c-text__textContainer">
            <p class="c-text__text">
              At Dilepex, we recognise that by integrating technology we are
              able to facilitate schools, and educational institutions, in
              innovating and delivering smart, specific training to not only the
              teachers, but also to those who they serve on a daily basis –
              students and parents.
            </p>
            <p class="c-text__text">
              Our aim is to drive student engagement and focused learning in a
              hybrid learning environment. Every school desires agility,
              efficiencies and the tools to enhance and satisfy the need for
              academic excellence, with the primary objective of outstanding
              learning experiences and outcomes for all its students. There is a
              strong demand for collaboration, accessibility and teacher
              time-saving solutions.
            </p>
            <p class="c-text__text">
              Our Microsoft Teams and Class Notebook training enables you to
              enjoy the benefits of enhanced productivity, collaborations, data
              insights and automation. We provide the training needed to produce
              outcomes desired while realising greater value. This will generate
              further innovative students’ engagement, transform school
              strategies and empower you to reimagine the world of education.
            </p>
          </div>
        </div>
      </section>
      <div class="spacing-48px"></div>
      <section class="sectionContainer">
        <div class="c-cardContent responsivePadding">
          <div class="c-cardContent__container">
            <h2 class="c-cardContent__title title--h2" style={{ margin: 40 }}>
              Digital Pedagogy
            </h2>
            <div class="c-cardContent__textGroup">
              <div class="c-cardContent__textContainer">
                <p class="c-cardContent__text">
                  Education has evolved.Students connecting remotely, learning
                  in distinct approaches and demanding more from technology.In
                  current learning environments, teachers must look to advance
                  from conventional classrooms to the application of digital
                  pedagogy.And all of this must be done on stretched/reduced
                  budgets.
                </p>
                <p class="c-cardContent__text">
                  Education has evolved.Students connecting remotely, learning
                  in distinct approaches and demanding more from technology.In
                  current learning environments, teachers must look to advance
                  from conventional classrooms to the application of digital
                  pedagogy.And all of this must be done on stretched/reduced
                  budgets.
                </p>
                <p class="c-cardContent__text">
                  Education has evolved.Students connecting remotely, learning
                  in distinct approaches and demanding more from technology.In
                  current learning environments, teachers must look to advance
                  from conventional classrooms to the application of digital
                  pedagogy.And all of this must be done on stretched/reduced
                  budgets.
                </p>
              </div>
            </div>
            <div class="c-cardContent__cardGroup">
              <h3 class="c-cardContent__title title--h3">The benefits</h3>
              <div class="c-cardContent__cards">
                <div class="c-cardContent__card">
                  <p class="c-cardContent__cardTitle text--b">
                    <strong>Students</strong>
                  </p>
                  <p class="c-cardContent__cardText text--m">
                    Digital intelligence ensures that students have the
                    essential skills and ingenuity to satisfy demands of an
                    ever-evolving digital world. <br></br>
                    <br></br>
                    They know how to effectively use technology, giving them
                    greater confidence to navigate around it.
                  </p>
                </div>
                <div class="c-cardContent__card">
                  <p class="c-cardContent__cardTitle text--b">
                    {" "}
                    <strong>Teacher</strong>
                  </p>
                  <p class="c-cardContent__cardText text--m">
                    The application of technology helps save time and provides
                    much higher standards of teaching and learning.<br></br>
                    <br></br>
                    It enables every teacher to deliver exciting and engaging
                    lessons and inspire students to make the most progress
                    possible.
                  </p>
                </div>
                <div class="c-cardContent__card">
                  <p class="c-cardContent__cardTitle text--b">
                    <strong>Schools</strong>
                  </p>
                  <p class="c-cardContent__cardText text--m">
                    Technology offers an agile platform for innovation whilst
                    supporting the efficient running of your school.
                    <br></br>
                    <br></br>
                    It provides key insights to students academic progress and
                    their well-being through tracking, monitoring and
                    evaluation.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="spacing-48px"></div>
      <section class="sectionContainer bgPureWhite">
        <div class="c-iconblks responsivePadding">
          <h2 class="c-iconblks__title c-iconblks__title--teal">
            Learn with us ...
          </h2>
          <div class="c-iconblks__container c-iconblks__container--triple">
            <div class="c-iconblks__iconblk">
              <img src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/onlineTrainingVideos.svg" />
              <p class="c-iconblks__contentTitle">Online training videos</p>
            </div>
            <div class="c-iconblks__iconblk">
              <img src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/expertTrainingProviders.svg" />
              <p class="c-iconblks__contentTitle">Expert training providers</p>
            </div>
            <div class="c-iconblks__iconblk">
              <img src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/subjectSpecificFocus.svg" />
              <p class="c-iconblks__contentTitle">Subject specific focus</p>
            </div>
            <div class="c-iconblks__iconblk">
              <img src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/learnAtYourOwnPace.svg" />
              <p class="c-iconblks__contentTitle">Learn at your own pace</p>
            </div>
            <div class="c-iconblks__iconblk">
              <img src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/professionalCertification.svg" />
              <p class="c-iconblks__contentTitle">Professional certification</p>
            </div>
            <div class="c-iconblks__iconblk">
              <img src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/sharingGoodPractice.svg" />
              <p class="c-iconblks__contentTitle">Sharing good practice</p>
            </div>
          </div>
          <h2 class="c-iconblks__title c-iconblks__title--blue">
            ... and address the challenges
          </h2>
          <div class="c-iconblks__container c-iconblks__container--quad">
            <div class="c-iconblks__iconblk">
              <img src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/computerTime.svg" />
              <p class="c-iconblks__fixedText">
                Offer excellent time-saving and collaborative training, despite
                stretched budgets
              </p>
            </div>
            <div class="c-iconblks__iconblk">
              <img src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/computerSeminar.svg" />
              <p class="c-iconblks__fixedText">
                Improve digital learning and enable your students to gain the
                necessary digital skills
              </p>
            </div>
            <div class="c-iconblks__iconblk">
              <img src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/atom.svg" />
              <p class="c-iconblks__fixedText">
                Enable diversity, accessibility and inclusion within all areas
                and aspects of school life
              </p>
            </div>
            <div class="c-iconblks__iconblk">
              <img src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/jigsaw.svg" />
              <p class="c-iconblks__fixedText">
                Adapt to all the curriculum updates and the pressure for grade
                improvements
              </p>
            </div>
          </div>
        </div>
      </section>
      <div class="spacing-48px"></div>
      <div class="spacing-48px"></div>
      <div class="bgContainer linearBG">
        <section class="sectionContainer">
          <div class="c-text c-text--ourEthos responsivePadding">
            <h2 class="c-text__title">Our ethos</h2>
            <div class="c-text__textContainer">
              <p class="c-text__text">
                With many years of combined experience in education - in IT and
                in teaching roles - our expert understanding of the need for
                successful integration of digital pedagogy in today’s teaching
                and learning is simply unparallel. We were part of an
                institution which was shortlisted for the world’s best school
                prize for innovation.
              </p>
              <p class="c-text__text">
                The Dilepex team is cherry-picked because we have the desire,
                emotional intelligence, and personality to thrive in fast-paced
                and innovative learning environments. Ingenuity and hard work
                are at the heart and soul of each of our employees, and in
                everything that we do.
              </p>
              <p class="c-text__text">
                We work in collaborative environments, where the creative and
                strategic minds synchronise. We are constantly learning and
                training to be fully up-to-date with education’s best practises.
                We intend to influence behaviours, enhance ways of working, and
                free up your people to do more of what matters to them.
              </p>
            </div>
          </div>
        </section>
        <div class="spacing-48px"></div>
        <div class="spacing-48px"></div>
        <section class="sectionContainer">
          <div class="c-blogTb responsivePadding">
            <div class="c-blogTb__header">
              <h2 class="c-blogTb__headerHeading">The Executive team</h2>
              <button class="c-button c-button--whiteOutline c-button--desktop">
                Read more
              </button>
            </div>
            <div class="c-blogTb__thumbnails">
              <div class="c-blogTb__thumbnail">
                <img
                  src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/blog/interview.jpg"
                  alt=""
                  class="c-blogTb__thumbnailImg"
                />
                <div class="c-tag c-tag--tips">Tips</div>
                <div class="c-blogTb__thumbnailTitle">
                  Sue Standen
                  <div class="c-blogSubtitle">
                    <div class="c-blogSubtitle__tag">CEO</div>
                  </div>
                </div>
              </div>
              <div class="c-blogTb__thumbnail">
                <img
                  src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/blog/teacher.jpg"
                  alt=""
                  class="c-blogTb__thumbnailImg"
                />
                <div class="c-tag c-tag--updates">Updates</div>
                <div class="c-blogTb__thumbnailTitle">
                  Morne Hoffman
                  <div class="c-blogSubtitle">
                    <div class="c-blogSubtitle__tag">COO</div>
                  </div>
                </div>
              </div>
              <div class="c-blogTb__thumbnail">
                <img
                  src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/blog/childVR.jpg"
                  alt=""
                  class="c-blogTb__thumbnailImg"
                />
                <div class="c-tag c-tag--news">News</div>
                <div class="c-blogTb__thumbnailTitle">
                  Maaz Khan
                  <div class="c-blogSubtitle">
                    <div class="c-blogSubtitle__tag">Founder</div>
                  </div>
                </div>
              </div>
            </div>
            <button class="c-button c-button--whiteOutline c-button--mobile">
              Read more
            </button>
          </div>
        </section>
        <div class="spacing-64px"></div>
        <section class="sectionContainer">
          <div class="c-cta">
            <div class="c-cta__title">Get in touch</div>
            <p class="c-cta__intro">
              Arrange an initial conversation with one of our digital learning
              pedagogy experts
            </p>
            <button
              class="c-button ctaOrange"
              style={{ background: "#424242", color: "white" }}
              onClick={() => {
                history("/contact");
              }}
            >
              Contact us
            </button>
          </div>
        </section>
      </div>
    </div>
  );
};

export default About;
