import React from "react";
import "./style.css";
import logo from "../../Assets/Images/Logo-Bird-2.png";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const history = useNavigate();

  return (
    <div>
      <footer class="sectionContainer bgBlueDark">
        <div class="c-footer responsivePadding">
          <div class="c-footer__topGroup">
            <div class="c-footer__group c-footer__group--left">
              <div class="c-footer__title">
                Changing the future, with our newsletters
              </div>
              <div class="c-newsletter">
                <div class="c-newsletter__placeholder">Enter your email</div>
                <a class="c-newsletter__button">Sign up</a>
              </div>
            </div>
            <div class="c-footer__group c-footer__group--right">
              <div
                style={{
                  display: "flex",
                  width: 230,
                  justifyContent: "space-between",
                  padding: 13,
                  // background: "#ffd787d1",
                  borderRadius: 12,
                }}
              >
                {/* <a href="">
            <img src={logo} alt="" class="c-nav__logo" />
          </a> */}
                <div>
                  <a href="">
                    <img src={logo} alt="" class="c-nav__logo" />
                  </a>
                </div>
                <div style={{ marginLeft: 5, cursor: "pointer" }}>
                  <div
                    style={{
                      fontFamily: "montserrat",
                      fontSize: 25,
                      fontWeight: 700,
                      letterSpacing: 2,
                      color: "rgb(136 112 49)",
                    }}
                  >
                    Learnogic
                  </div>
                  <div
                    style={{
                      fontFamily: "montserrat",
                      fontSize: 12,
                      fontWeight: 600,
                      letterSpacing: 2,
                      color: "#fff  ",
                    }}
                  >
                    Raise Your Level
                  </div>
                </div>
              </div>{" "}
              <ul class="c-footer__links">
                <a
                  onClick={() => {
                    history("/contact");
                  }}
                  class="c-footer__link"
                >
                  Contact us
                </a>
                <a
                  onClick={() => {
                    history("/subjects");
                  }}
                  class="c-footer__link"
                >
                  Subjects
                </a>
                <a href="" class="c-footer__link">
                  FAQs
                </a>
              </ul>
              <ul class="c-socialIcons">
                <a href="">
                  <i class="fa-brands fa-facebook"></i>
                </a>
                <a href="">
                  <i class="fa-brands fa-twitter"></i>
                </a>
                <a href="">
                  <i class="fa-brands fa-pinterest"></i>
                </a>
                <a href="">
                  <i class="fa-brands fa-youtube"></i>
                </a>
              </ul>
            </div>
          </div>
          <div class="c-footer__tnc">
            <a href="">Terms &amp; Conditions</a>|<a href="">Privacy Policy</a>©
            Copyright Learnogic
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
