import React from "react";
import "./style.css";
import { useForm } from "react-hook-form";

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm();
  const watchFields = watch();
  return (
    <div>
      <div class="headerSpacing"></div>
      <section class="sectionContainer lgDark">
        <div class="c-contact responsivePadding">
          <div class="c-contact__content">
            <h1 class="c-contact__contentTitle">Contact form</h1>
            <p class="c-contact__contentIntro">
              If you would like to learn more, begin or continue your digital
              learning journey with us, please get in touch and a member of our
              team will connect with you soon.
            </p>
            <div class="c-contact__infos">
              <div class="c-contact__info">
                <i class="fa-solid fa-address-book c-contact__infoIcon"></i>
                <div class="c-contact__infoContent">
                  <div class="c-contact__infoContentTitle">
                    Contact us by email
                  </div>
                  <div class="c-contact__infoContentText">
                    learning@dilepex.com
                  </div>
                </div>
              </div>
              <div class="c-contact__info">
                <i class="fa-solid fa-phone c-contact__infoIcon"></i>
                <div class="c-contact__infoContent">
                  <div class="c-contact__infoContentTitle">
                    Contact us by phone
                  </div>
                  <div class="c-contact__infoContentText">+44 7487220123</div>
                </div>
              </div>
            </div>
          </div>
          <div class="c-contact__form">
            <form className="form-container">
              <div className="form-div">
                {" "}
                <div className="form-text-div">
                  <div className="Labelcontent">
                    School or Organisation Name
                    <span
                      style={{
                        fontWeight: "300",
                        color: "red",
                        fontSize: 13,
                        fontStyle: "italic",
                      }}
                    >
                      (Required)
                    </span>
                  </div>

                  <input
                    placeholder="School or Organisation Name"
                    className="inputContent"
                    {...register("school_name", { required: true })}
                  />
                </div>
                <div className="form-text-div">
                  <div className="Labelcontent">
                    Name{" "}
                    <span
                      style={{
                        fontWeight: "300",
                        color: "red",
                        fontSize: 13,
                        fontStyle: "italic",
                      }}
                    >
                      (Required)
                    </span>
                  </div>

                  <input
                    placeholder="Name"
                    className="inputContent"
                    {...register("name", { required: true })}
                  />
                </div>
              </div>
              <div className="form-div">
                <div className="form-text-div">
                  <div className="Labelcontent">
                    Email Address{" "}
                    <span
                      style={{
                        fontWeight: "300",
                        color: "red",
                        fontSize: 13,
                        fontStyle: "italic",
                      }}
                    >
                      (Required)
                    </span>
                  </div>

                  <input
                    placeholder="Email Address"
                    className="inputContent"
                    {...register("email_address", { required: true })}
                  />
                  {errors.name && (
                    <span className="error-message">
                      This field is required
                    </span>
                  )}
                </div>
                <div className="form-text-div">
                  <div className="Labelcontent">
                    Job Title / Position{" "}
                    <span
                      style={{
                        fontWeight: "300",
                        color: "red",
                        fontSize: 13,
                        fontStyle: "italic",
                      }}
                    >
                      (Required)
                    </span>
                  </div>

                  <input
                    placeholder="Job Title / Position"
                    className="inputContent"
                    {...register("position", { required: true })}
                  />
                </div>
              </div>
              <div className="form-div">
                <div className="form-text-div">
                  <div className="Labelcontent">
                    Select course(s) you would like to know more about *{" "}
                    <span
                      style={{
                        fontWeight: "300",
                        color: "red",
                        fontSize: 13,
                        fontStyle: "italic",
                      }}
                    >
                      (Required)
                    </span>
                  </div>

                  <select
                    className="inputContent"
                    {...register("course", { required: true })}
                  >
                    <option value="">Select a course</option>
                    <option value="Business">Business</option>
                    <option value="Economy">Economy</option>
                    <option value="Politics">Politics</option>
                  </select>
                </div>

                <div className="form-text-div" style={{ marginTop: "57px" }}>
                  <div className="Labelcontent">
                    How did you hear about Learnogic?
                    <span
                      style={{
                        fontWeight: "300",
                        color: "red",
                        fontSize: 13,
                        fontStyle: "italic",
                      }}
                    >
                      (Required)
                    </span>
                  </div>

                  <select
                    className="inputContent"
                    {...register("find", { required: true })}
                  >
                    <option value="">Select an option</option>
                    <option value="social_media">Social Media</option>
                    <option value="friend_and_family">Friend and Family</option>
                    <option value="google">Google</option>
                    <option value="other">Other</option>
                  </select>
                </div>
              </div>
              <div className="form-text-div ">
                <div className="Labelcontent">
                  Further information and details
                </div>

                <input
                  placeholder="Please enter your message"
                  className="inputContent"
                  style={{ width: "100%", height: 120 }}
                  {...register("description")}
                />
              </div>
              <div style={{ marginTop: 20 }}>
                <button type="submit" className="submitButton">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactForm;
